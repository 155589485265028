.CalculationReviewSidebarTabs {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.CalculationReviewSidebarTabs .tab-content {
    height: 100%;
    overflow: auto;
}
