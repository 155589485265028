.UserProfileSideNav {
  padding: 10px;
  border-left: 2px solid rgb(199, 199, 199);
}

.UserProfileSideNav span a{
  color: #1a1c21;
  text-overflow: ellipsis;
}

.UserProfileSideNav div .euiSideNavItemButton-isSelected a {
  color: #006BB4
}

.UserProfileSideNav span a:hover{
  text-decoration: underline;
}
