.ReactTable {
    @tableInputHeight: 28px;
    @rowSelectedBackgroundColor: #dff0d8;

    input[type="checkbox"] {
        box-shadow: initial;
        height: initial;
        margin: initial;
    }

    .rt-td {
        padding: 2px 5px;
    }

    .rt-tr.-selected {
        background-color: @rowSelectedBackgroundColor !important;
    }

    .rt-filter {
        input {
            width: 100%;
            height: @tableInputHeight;
        }
    }


    .rt-thead {
        overflow-y: visible;

        .-filters select {
            height: @tableInputHeight;
        }
    }

    .rt-tbody {
        overflow-y: visible;
    }


    .progress {
        margin: 5px 0;
    }

    /* react-datetime */

    .rdt {
        position: absolute;
    }

    .rt-thead.-filters .-moment-filter {
        position: static;
    }
}
