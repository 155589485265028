@import "../_app_variables";

//
// Styling for timelines view of plan board
//
.PlanBoardBlocks {
    // Toolbar
    @toolbarHeight: 35px;
    @toolbarTotalHeight: @toolbarHeight;

    // Time axis
    @axisWrapperHeight: 50px;
    @axisWrapperTotalHeight: @axisWrapperHeight + @margin;

    // Number of dynamic-height panels. Available height will be spread among them.
    @numPanels: 2;
    // Share of each panel out of available vertical space
    //@panelHeight: ~"calc(100% / @{numPanels} - @{axisWrapperTotalHeight})";

    // Bottom bar
    @bottomBarHeight: 0px;
    //@bottomBarHeight: 36px;
    //@bottomBarTotalHeight: @bottomBarHeight + 2 * @margin;
    @bottomBarTotalHeight: 0px;

    // Add up all fixed height elements on the page.
    @fixedHeightElements: @App__topBarHeight +
            @toolbarHeight +
            @axisWrapperTotalHeight +
            @toolbarHeight +
            @bottomBarTotalHeight;

    //
    // styles
    //

    height: 100%;
    width: 100%;

    .PlanBoardTimelines__axis-wrapper {
        //border: 1px solid @color3;
        border-top: 0;
        border-bottom: 0;
        background-color: #fff;

        height: @axisWrapperHeight;
        overflow-y: scroll;
        overflow-x: hidden;

        table {
            width: 100%;
        }
    }

    .PlanBoardTimelines__block {
        //height: 100%;
        //width: 100%;
        //display: flex;
        //flex-direction: column;
        //border-bottom: 1px solid @color3;
    }

    .PlanBoardTimelines__block__divider {
        height: 1px;
        background: #AAA;
    }

    .PlanBoardTimelines__divider {
        height: 5px;
        background: @color3;
    }

    .PlanBoardTimelines__block-toolbar {
        height: @toolbarHeight;
        background: @color3;
    }

    .PlanBoardTimelines__scrollable-panel {
        height: ~"calc(100% - @{axisWrapperHeight})";
        width: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        background: #fff;
        position: relative;

        table {
            width: 100%;
            height: 100%;
        }
    }
}

.PlanBoardTimelines__invisible-row {
    height: 0;
    width: 100%;
    overflow-y: scroll;
}
