.stub-container-wrapper {
    height: 100%;
    padding: 6px 0;
}

.stub-wrapper {
    width: 100%;
    height: 100%;
}

.stub {
    height: 100%;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    border: 1px solid @color1;
    border-radius: 2px;

    z-index: 10 !important;
}

.stub-wrapper-selected .stub {
    outline: none;
    box-shadow: 0 0 5px yellow;
    border: 1px solid yellow !important;
}

.stub-wrapper-selected-multi .stub {
    outline: none;
    box-shadow: 0 0 5px yellow;
    border: 1px solid yellow !important;
}

.stub-wrapper-highlighted .stub {
    outline: none;
    box-shadow: 0 0 5px blue;
    border: 1px solid blue !important;
}
