@import 'vendor/_vendor.less';
@import 'components/_components.less';
@import '_app_variables.less';
@import '_variables.less';
@import '_youplan.less';

.table-nonfluid {
    width: auto !important;
}

.table-borderless-cells > thead > tr > td,
.table-borderless-cells > thead > tr > th,
.table-borderless-cells > tbody > tr > td,
.table-borderless-cells > tbody > tr > th {
    border: none !important;
}
