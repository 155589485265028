.PlanTable {
    height: 100%;
    width: auto;
    padding-left: 40px;
    padding-top: 20px;
    padding-right: 20px;
    overflow: auto;
}

.PlanTable table > tbody > tr > td {
    border: 0;
}

.PlanTableWorkRow {
    border-bottom: 1px solid #AAA;
}

.PlanTable .progress {
    margin-bottom: 0;
}
