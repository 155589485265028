.popoverDateTime {
    margin-top: -10px;
    margin-left: -10px;
    width: 240px;
    height: 225px;
}

.popoverDateTime .rdtPicker {
    border: none;
    box-shadow: none;
}
