.rw-multiselect.rw-state-focus, .rw-multiselect.rw-state-focus:hover  {
    border: 1px solid #BDC4C9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.rw-multiselect-wrapper > .rw-input {
    height: 34px;
}

ul.rw-list > li.rw-list-option.rw-state-focus, .rw-selectlist > li.rw-list-option.rw-state-focus {
    border: 1px solid #BDC4C9;
}