.DayBackground {
    //display: inline-block;
    position: relative;
    width: 100%;
    //border-right: 1px solid #ccc;
    border: 0;
}

.DayBackground__date {
    font-size: 14px;
    font-weight: normal;
}

.DayBackground__axis {
    font-size: 8px;
    fill: #555;

    text {
        fill: #555;
    }

    line, path {
        shape-rendering: auto;
        stroke: #bbb;
        stroke-width: 1;
    }

    path {
        //fill: none;
    }

    .tick {
        shape-rendering: auto;
    }

    .domain {
        //stroke-width: 1px;
    }

    .axis.hours {
        font-weight: bold;
    }
}

