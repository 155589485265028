.PlanBoardPanel {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    //border: 1px solid #ddd;
    background-color: #fff;
}

.PlanBoardPanel .tab-content {
    display: none;
}

.PlanBoard {
    height: 100%;
}
