//
// Style for TimelineRow component
//

.TimelineRow {
  display: flex;
  flex-direction: row;
  height: 50px;
}

.TimelineRow__lead {
  display: inline-block;
  width: 14%;
  height: 50px;
  padding: 0 15px 0 5px;
}

.TimelineRow__timeline {
  position: relative;
  display: inline-block;
  width: 86%;
  height: 50px;
  //padding-bottom: 1px;
  //border-bottom: 1px solid #ccc;
}

.TimelineRow__timeline-border-bottom {
  padding-bottom: 0;
  border-bottom: 1px solid #ccc;
}

.TimelineRow.has-no-ftf {
  opacity: 0.4;
}

.TimelineRow.has-difference {
  opacity: 0.6;
}

.TimelineRow.on-hold {
  opacity: 0.5;
}

.WorkTimeline.darkened {
  filter: brightness(70%);
}

.WorkTimeline.highlighted {
  //border: 1px solid #00B8D4;
  border: solid 1px #999; /* Base border color */
  box-shadow: 0 0 10px #fff; /* Outer glow for the highlighted effect */
}
