.EuiHeaderNav {
    background: #222;
    box-shadow: none;
}

.EuiHeaderNav a, .EuiHeaderNav .euiHeaderLogo {
    color: #9d9d9d;
    padding-left: 20px;
    padding-right: 10px;

    .AppLogo {
            //width: auto;
            padding: 0;
            //vertical-align: top;
            height: 25px;
            //padding-top: 5px;
            //padding-left: 10px;
    }
}



.EuiHeaderNav a:hover {
    color: white;
}

.AppLogoText {
    margin: 0;
    font-size: 25px;
    color: white;
    padding-top: 5px;
}

.SideNav {
    max-width: 250px;
}

.sidebarHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 52px;
    background: #222;

    .AppLogoSB {
        //width: auto;
        height: 25px;
        padding-right: 10px;
        //margin-left: auto;
    }
}

ul.dropdown-menu {
    margin-top: 12px;
    right: 0;
    left: auto;
}
