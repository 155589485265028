.applogo {
    min-width: 250px;
    margin-right: 20px;
}

nav {
  line-height: initial;
}

.navbar-brand img {
  height: 20px;
}
