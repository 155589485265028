.rdt {
    padding-top: 0;
}

.rdtDay {
    cursor: pointer;

    &:hover {
        background: #ddd;
    }
}

.rdtPicker {
    border: 1px solid #BDC4C9;
}

.rdtPicker th td {
    vertical-align: middle !important;
}

.dow, .rdtPrev, .rdtNext {
    vertical-align: middle !important;
}

.rdtSwitch {
    height: 31px !important;
    vertical-align: middle !important;
}
