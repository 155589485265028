@planBoardToolbarHeight: 37px;

.PlanBoardToolbar {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 10px;
    .euiButton{
        min-width: 0;
        height: 28px;
    }

    input {
        max-height: 30px;
    }

    .form-group {
        margin-right: 10px;
    }

    height: @planBoardToolbarHeight;
    padding-left: 10px;
}

.SelectFieldItem {
    width: 100%;
    margin: 0;

    p {
        margin-bottom: 0px;
        padding: 10px;
    }

    a {
        color: #58666E;
    }
}

.SelectFieldItem:hover {
    background-color: lightgrey;
}

.SelectFieldCustomItem:hover {
    background-color: lightgrey;
}

.SelectFieldCustomItem {
    width: 100%;
    margin: 0;
    padding: 10px;


    label {
        font-size: 13px !important;
        font-weight: 10 !important;
    }
}


