.panel {
    .panel-title {
        padding: 16px 20px;
        margin: 0;
    }

    .panel-warnings, .panel-actions, .panel-tabs, .panel-body, .panel-header {
        padding: 16px 20px;
        margin: 0;
    }

    .panel-actions {
        .list-group {
            margin: 0;
        }

        .list-group-item {
            margin-bottom: 0;
            max-width: 500px;
            font-weight: lighter;
            border: 0;
        }

        .separator {
            border: 0;
            height: 5px;
        }
    }

    .panel-actions, .panel-tabs {
        text-transform: none;
    }

    .panel-tabs {
        margin: 0 -20px;
        background-color: #fcfcfc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }
}
